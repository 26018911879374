.katex .mathnormal {
  font-family: 'Happy Karrik', sans-serif;
  font-style: normal;
  font-size: inherit;
}
.katex {
  font-size: inherit;
  font-family: 'Happy Karrik', sans-serif;
  font-style: normal;
  font: inherit;
}

.katex * {
  font-size: inherit;
  font-family: 'Happy Karrik', sans-serif;
  font-style: normal;
}

.katex .mord.mathnormal {
    margin-right: 0 !important;
}