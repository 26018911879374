/* AiChat.module.css */
@keyframes slideFromBelow {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .animatedText {
    animation: slideFromBelow 0.5s ease-out;
  }
  

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .slideInRight {
    will-change: transform, opacity;
    animation: 0.5s ease-out 0s 1 slideInFromRight;
  }
  


  .rainbow-hover:hover .iconify {
    animation: rainbow 2s linear infinite;
  }
  
  @keyframes rainbow {
    0% { filter: hue-rotate(0deg); }
    100% { filter: hue-rotate(360deg); }
  }


  /* App.css */
.custom-scrollbar::-webkit-scrollbar {
  height: '100%'; /* Increase the height for horizontal scrollbar */
  width: '100%';
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
